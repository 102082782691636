import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2220c0d6 = () => interopDefault(import('../pages/check-email.vue' /* webpackChunkName: "pages/check-email" */))
const _004a25d4 = () => interopDefault(import('../pages/ian.vue' /* webpackChunkName: "pages/ian" */))
const _73475be7 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _42b5297c = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _7db80f05 = () => interopDefault(import('../pages/medications/index.vue' /* webpackChunkName: "pages/medications/index" */))
const _2433f10a = () => interopDefault(import('../pages/password-updated.vue' /* webpackChunkName: "pages/password-updated" */))
const _6a35ca46 = () => interopDefault(import('../pages/paylinks/index.vue' /* webpackChunkName: "pages/paylinks/index" */))
const _7b32475a = () => interopDefault(import('../pages/prescriptions/index.vue' /* webpackChunkName: "pages/prescriptions/index" */))
const _491e40d6 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _1f743974 = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _d71cc0f6 = () => interopDefault(import('../pages/resend-confirmation.vue' /* webpackChunkName: "pages/resend-confirmation" */))
const _0961786a = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _6114ad15 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _0e65f95e = () => interopDefault(import('../pages/settings/close-account.vue' /* webpackChunkName: "pages/settings/close-account" */))
const _0ce966c4 = () => interopDefault(import('../pages/settings/export.vue' /* webpackChunkName: "pages/settings/export" */))
const _b3b6ad7c = () => interopDefault(import('../pages/settings/hospitals/index.vue' /* webpackChunkName: "pages/settings/hospitals/index" */))
const _b9ef77b8 = () => interopDefault(import('../pages/settings/options.vue' /* webpackChunkName: "pages/settings/options" */))
const _7c4cbdfb = () => interopDefault(import('../pages/settings/pharmacies.vue' /* webpackChunkName: "pages/settings/pharmacies" */))
const _66213daa = () => interopDefault(import('../pages/settings/security.vue' /* webpackChunkName: "pages/settings/security" */))
const _4e992db5 = () => interopDefault(import('../pages/settings/user.vue' /* webpackChunkName: "pages/settings/user" */))
const _45a5e910 = () => interopDefault(import('../pages/settings/hospitals/new.vue' /* webpackChunkName: "pages/settings/hospitals/new" */))
const _87b959a6 = () => interopDefault(import('../pages/settings/hospitals/_id/index.vue' /* webpackChunkName: "pages/settings/hospitals/_id/index" */))
const _24fb367f = () => interopDefault(import('../pages/settings/hospitals/_id/edit.vue' /* webpackChunkName: "pages/settings/hospitals/_id/edit" */))
const _25a70183 = () => interopDefault(import('../pages/wrapped.vue' /* webpackChunkName: "pages/wrapped" */))
const _7357dca4 = () => interopDefault(import('../pages/embed/rx.vue' /* webpackChunkName: "pages/embed/rx" */))
const _7597a5b0 = () => interopDefault(import('../pages/prescriptions/new.vue' /* webpackChunkName: "pages/prescriptions/new" */))
const _71d7afdf = () => interopDefault(import('../pages/redirect/new.vue' /* webpackChunkName: "pages/redirect/new" */))
const _acc08e60 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _687f28fe = () => interopDefault(import('../pages/paylinks/_slug.vue' /* webpackChunkName: "pages/paylinks/_slug" */))
const _32991b3e = () => interopDefault(import('../pages/prescriptions/_signature.vue' /* webpackChunkName: "pages/prescriptions/_signature" */))
const _4359587a = () => interopDefault(import('../pages/new-password/_uid/_token.vue' /* webpackChunkName: "pages/new-password/_uid/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/check-email",
    component: _2220c0d6,
    name: "check-email"
  }, {
    path: "/ian",
    component: _004a25d4,
    name: "ian"
  }, {
    path: "/login",
    component: _73475be7,
    name: "login"
  }, {
    path: "/logout",
    component: _42b5297c,
    name: "logout"
  }, {
    path: "/medications",
    component: _7db80f05,
    name: "medications"
  }, {
    path: "/password-updated",
    component: _2433f10a,
    name: "password-updated"
  }, {
    path: "/paylinks",
    component: _6a35ca46,
    name: "paylinks"
  }, {
    path: "/prescriptions",
    component: _7b32475a,
    name: "prescriptions"
  }, {
    path: "/register",
    component: _491e40d6,
    name: "register"
  }, {
    path: "/registered",
    component: _1f743974,
    name: "registered"
  }, {
    path: "/resend-confirmation",
    component: _d71cc0f6,
    name: "resend-confirmation"
  }, {
    path: "/reset-password",
    component: _0961786a,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _6114ad15,
    name: "settings",
    children: [{
      path: "close-account",
      component: _0e65f95e,
      name: "settings-close-account"
    }, {
      path: "export",
      component: _0ce966c4,
      name: "settings-export"
    }, {
      path: "hospitals",
      component: _b3b6ad7c,
      name: "settings-hospitals"
    }, {
      path: "options",
      component: _b9ef77b8,
      name: "settings-options"
    }, {
      path: "pharmacies",
      component: _7c4cbdfb,
      name: "settings-pharmacies"
    }, {
      path: "security",
      component: _66213daa,
      name: "settings-security"
    }, {
      path: "user",
      component: _4e992db5,
      name: "settings-user"
    }, {
      path: "hospitals/new",
      component: _45a5e910,
      name: "settings-hospitals-new"
    }, {
      path: "hospitals/:id",
      component: _87b959a6,
      name: "settings-hospitals-id"
    }, {
      path: "hospitals/:id/edit",
      component: _24fb367f,
      name: "settings-hospitals-id-edit"
    }]
  }, {
    path: "/wrapped",
    component: _25a70183,
    name: "wrapped"
  }, {
    path: "/embed/rx",
    component: _7357dca4,
    name: "embed-rx"
  }, {
    path: "/prescriptions/new",
    component: _7597a5b0,
    name: "prescriptions-new"
  }, {
    path: "/redirect/new",
    component: _71d7afdf,
    name: "redirect-new"
  }, {
    path: "/",
    component: _acc08e60,
    name: "index"
  }, {
    path: "/paylinks/:slug",
    component: _687f28fe,
    name: "paylinks-slug"
  }, {
    path: "/prescriptions/:signature",
    component: _32991b3e,
    name: "prescriptions-signature"
  }, {
    path: "/new-password/:uid?/:token?",
    component: _4359587a,
    name: "new-password-uid-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
