import { render, staticRenderFns } from "./centered.vue?vue&type=template&id=7cc467ae&scoped=true&"
import script from "./centered.vue?vue&type=script&lang=js&"
export * from "./centered.vue?vue&type=script&lang=js&"
import style0 from "./centered.vue?vue&type=style&index=0&id=7cc467ae&prod&lang=css&"
import style1 from "./centered.vue?vue&type=style&index=1&id=7cc467ae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cc467ae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Alert: require('/tmp/build_88800457/components/alert.vue').default})
